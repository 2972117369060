import React, { useMemo } from 'react';
import { PodSection, PodSpacer, ProductImage, ProductPod } from '@thd-olt-component-react/product-pod';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { string as stringType, number as numberType, bool as boolType } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { typeaheadEvent } from '../../events/typeahead.events';

// eslint-disable-next-line react/prop-types
const PodDetails = ({
  podItemId, brandTitleMaxLine, disableTitle, position, scheme
}) => {
  const padding = ['TOP', 'BOTTOM'];

  const merchQueryParam = useMemo(() => {
    return `MERCH=REC-_-${scheme}-_-${podItemId}-_-${position}-_-n/a-_-n/a-_-n/a-_-n/a-_-n/a`;
  }, [podItemId, position]);

  const Details = () => {
    return (
      <>
        <ProductImage itemId={podItemId} />
        {!disableTitle
          && (
            <PodSpacer padding={padding}>
              <div className="sui-text-primary">
                <ProductHeader
                  brand="hidden"
                  brandTitleMaxLine={brandTitleMaxLine}
                  data-testid="recently-viewed-product-title"
                  disableShopThisCollection
                  itemId={podItemId}
                  merchQueryParam={merchQueryParam}
                />
              </div>
            </PodSpacer>
          )}
      </>
    );
  };

  return (
    <PodSection columnAlign>
      <Details />
    </PodSection>
  );
};

PodDetails.propTypes = {
  brandTitleMaxLine: numberType,
  disableTitle: boolType,
  podItemId: stringType.isRequired,
  position: numberType,
  scheme: 'rv_typeahead_rr',
};

PodDetails.defaultProps = {
  brandTitleMaxLine: 2,
  disableTitle: false,
  position: 'n/a',
  scheme: 'rv_typeahead_rr',
};

const RecentlyViewedProductPod = ({
  itemId,
  storeId,
  brandTitleMaxLine,
  disableTitle,
  position,
}) => {

  const handleImageClick = (event) => {
    typeaheadEvent.publish({
      eventName: 'header-typeahead.recently-viewed-click',
      payload: {
        searchTerm: `rv:${itemId}`,
        displayPosition: position + 1,
      }
    });
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleImageClick}
    >
      <ProductPod
        itemId={itemId}
        storeId={storeId}
        render={(pod) => (
          <>
            <PodDetails
              podItemId={pod.itemId}
              brandTitleMaxLine={brandTitleMaxLine}
              disableTitle={disableTitle}
              position={position}
              scheme="rv_typeahead_rr"
            />
          </>
        )}
      />
    </div>
  );
};

RecentlyViewedProductPod.dataModel = extend(
  ProductPod,
  ProductHeader,
  ProductImage
);

RecentlyViewedProductPod.propTypes = {
  itemId: stringType,
  storeId: stringType,
  brandTitleMaxLine: numberType,
  disableTitle: boolType,
  position: numberType,
};

RecentlyViewedProductPod.defaultProps = {
  itemId: '',
  storeId: '',
  brandTitleMaxLine: 2,
  disableTitle: false,
  position: 'n/a',
};

export { RecentlyViewedProductPod };
