import React from 'react';
import {
  Bucket, BuyAgain, ListView, Profile, Drill, Hammer, Menu, BuyingGuide
} from '@one-thd/sui-icons';
import { MenuDataDictionary, MenuLevelDictionary } from '../dynamic-content/ContentMenuDictionary';
import MyAccountButtonLabel from '../drawer-ui/MyAccountContent/MyAccountButtonLabel';
import MyAccountMenuTitle from '../drawer-ui/MyAccountContent/MyAccountMenuTitle';
import { HeaderB2BAccountIcon } from './b2b/HeaderB2BAccountIcon';
import { HOST_CONSTANTS } from '../../constants';

const { DEFAULT_HOST } = HOST_CONSTANTS;

const B2C_NAV_BUTTONS = [
  {
    name: MenuDataDictionary.SHOP_ALL.title,
    flyoutId: MenuDataDictionary.SHOP_ALL.id,
    icon: Bucket
  },
  {
    name: MenuDataDictionary.SERVICES.title,
    flyoutId: MenuDataDictionary.SERVICES.id,
    icon: Drill
  },
  {
    name: MenuDataDictionary.DIY.title,
    flyoutId: MenuDataDictionary.DIY.id,
    icon: Hammer
  },
  {
    name: 'Account',
    customLabel: <MyAccountButtonLabel />,
    customDrawerTitle: <MyAccountMenuTitle />,
    icon: Profile
  }
];

const B2B_NAV_BUTTONS = [
  {
    name: MenuDataDictionary.MENU.title,
    flyoutId: MenuDataDictionary.MENU.id,
    icon: Menu
  },
  {
    name: 'Quotes',
    href: `${DEFAULT_HOST}/quote/list`,
    icon: BuyingGuide
  },
  {
    name: 'Lists',
    href: `${DEFAULT_HOST}/list/view/summary`,
    icon: ListView
  },
  {
    name: 'Account',
    customLabel: <MyAccountButtonLabel />,
    customDrawerTitle: <MyAccountMenuTitle />,
    icon: HeaderB2BAccountIcon
  }
];

const B2BEPR_NAV_BUTTONS = [
  {
    name: MenuDataDictionary.SHOP_ALL.title,
    customDrawerTitle: 'Shop All Products',
    flyoutId: MenuDataDictionary.MENU.id,
    icon: Bucket,
    type: MenuDataDictionary.SHOP_ALL.title,
    navigationLevel: MenuLevelDictionary.LEVEL_2
  },
  {
    name: 'Buy it Again',
    href: `${DEFAULT_HOST}/buyitagain`,
    icon: BuyAgain
  },
  {
    name: 'Lists',
    href: `${DEFAULT_HOST}/list/view/summary`,
    icon: ListView
  },
  {
    name: 'Account',
    customLabel: <MyAccountButtonLabel />,
    customDrawerTitle: <MyAccountMenuTitle />,
    icon: Profile
  }
];

const EXCHANGE_NAV_BUTTONS = [
  {
    name: MenuDataDictionary.EXCHANGE.title,
    flyoutId: MenuDataDictionary.EXCHANGE.id,
    icon: Menu
  },
  {
    name: 'Account',
    customLabel: <div>Me</div>,
    customDrawerTitle: <div>Me</div>,
    icon: Profile
  }
];

export { B2C_NAV_BUTTONS, B2B_NAV_BUTTONS, B2BEPR_NAV_BUTTONS, EXCHANGE_NAV_BUTTONS };
